import { UserManager } from "oidc-client-ts"
import { useParams, useSearchParams } from "react-router-dom"
import api from "./api";
import { useEffect } from "react";

const googleSettings = new UserManager({
    authority: "https://accounts.google.com/",
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "undefined",
    redirect_uri: process.env.REACT_APP_REDIRECT_URL_BASE + "/google" || "undefined",
    scope: "openid email profile",
})

const microsoftSettings = new UserManager({
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}/v2.0`,
    client_id: process.env.REACT_APP_MICROSOFT_CLIENT_ID || "undefined",
    redirect_uri: process.env.REACT_APP_REDIRECT_URL_BASE + "/microsoft" || "undefined",
    scope: "openid email profile",
})

type LoginButtonProps = {
    provider: string
    userManger: UserManager
}

function LoginButton(props: LoginButtonProps) {
    return (
        <div className="login-button-root" onClick={() => props.userManger.signinRedirect()}>
            <img alt="" className="login-button-img" src={`/icons/${props.provider}.png`}></img>
            <span className="login-button-label">Sign in with {props.provider.charAt(0).toUpperCase() + props.provider.substring(1)}</span>
        </div>
    )
}

export function Login() {

    return (
        <div className="login-root theme-surface">
            <div className="format-font-header-medium">Billablez</div>
            <div className="format-font-medium">Make calculating your billable hours a breeze</div>
            <div className="login-panel">
                <LoginButton provider="google" userManger={googleSettings} />
                <LoginButton provider="microsoft" userManger={microsoftSettings} />
            </div>
        </div>
    );
}

export function OidcReturn() {

    const [searchParams, setSearchParams] = useSearchParams()
    const { provider } = useParams()

    useEffect(() => {
        if (!provider) {
            return
        }

        const oidcRsp = JSON.parse(window.localStorage.getItem("oidc." + searchParams.get("state")) || "{}")
        if (!oidcRsp) {
            console.log("Missing oidc response item")
            // TODO log error
            return
        }
        console.log(oidcRsp)

        const code = searchParams.get("code")
        if (!code) {
            console.log("Missing oidc code")
            // TODO log error
            return
        }

        api.post("/auth/authenticate", { provider: provider, code: code, code_verifier: oidcRsp.code_verifier }).then(rsp => {
            window.localStorage.setItem("x-csrf-token", rsp.headers["x-csrf-token"])
            setSearchParams({})
            window.location.pathname = "/app/legal-aid/calculator"
        })
    }, [provider, searchParams, setSearchParams])

    return (
        <div className="login-root theme-surface">
            <div className="format-font-header-medium">Billablez</div>
            <div className="format-font-medium">Make calculating your billable hours a breeze</div>
            <div className="format-font-small">Sigining in...</div>
        </div>
    );
}

export default Login;

