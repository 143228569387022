// Import the functions you need from the SDKs you need
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App, Workspace } from './app/App';
import { Login, OidcReturn } from './app/Login';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Calculator from './app/legal_aid/Calculator';
import { PrivacyPolicy } from './legal/pp';
import { TermsOfService } from './legal/tos';

// TODO
// createRoutesFromElements
const router = createBrowserRouter([
    {
        path: "/*",
        element: <App />,
        // TODO errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Login />,
            },
            {
                path: "pp",
                element: <PrivacyPolicy />,
            },
            {
                path: "tos",
                element: <TermsOfService />,
            },
            {
                path: "auth/login/",
                element: <Login />,
            },
            {
                path: "auth/login/:provider",
                element: <OidcReturn />,

            },
            {
                path: "app/*",
                element: <Workspace />,
                children: [
                    {
                        path: "legal-aid/calculator",
                        element: <Calculator />,
                    },
                    {
                        path: "legal-aid/calculator/:invoiceUidParam",
                        element: <Calculator />,
                    }
                ]
            }
        ]
    }
]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
