export const LAWYER_TIER = new Map([
    ["tier-1", "Tier 1"],
    ["tier-2", "Tier 2"],
    ["tier-3", "Tier 3"],
    ["complex-case", "Complex Case"],
    ["law-clerks-et-al", "Law clerks and investigators in the employ of the roster memeber"],
    ["articling-students", "Articling Students"],
])

export const PLEA_TYPE = new Map([
    ["contested-trial", "Contested Trial"],
    ["guilty", "Guilty"],
    ["guilty-withdrawn", "Guilty (One or more charges withdrawn)"],
])

export const SEVERITY_OF_CHARGE = new Map([
    ["indictable-1", "Indictable 1"],
    ["indictable-2", "Indictable 2"],
    ["summary-convictions", "Summary Convictions"],
])

export function getTrialType(severityOfCharge: string, pleaType: string,) {
    if (severityOfCharge === "indictable-1") {
        if (pleaType === "guilty")
            return "ind1-guilty"
        else if (pleaType === "guilty-withdrawn")
            return "ind1-charges-withdrawn"
        else if (pleaType === "contested-trial")
            return "ind1-trial"
    } else if (severityOfCharge === "indictable-2") {
        if (pleaType === "guilty")
            return "ind2-guilty"
        else if (pleaType === "guilty-withdrawn")
            return "ind2-charges-withdrawn"
        else if (pleaType === "contested-trial")
            return "ind2-trial"
    } else if (severityOfCharge === "summary-convictions") {
        if (pleaType === "guilty")
            return "sc-guilty"
        else if (pleaType === "guilty-withdrawn")
            return "sc-charges-withdrawn"
        else if (pleaType === "contested-trial")
            return "sc-trial"
    }

    return "no-trial-found"
}

export function fromTrialType(trialType: string) {
    if (trialType === "ind1-guilty")
        return ["indictible-1", "guilty"]
    else if (trialType === "ind1-charges-withdrawn")
        return ["indictible-1", "guilty-withdrawn"]
    else if (trialType === "ind1-trial")
        return ["indictible-1", "contested-trial"]
    else if (trialType === "ind2-guilty")
        return ["indictible-2", "guilty"]
    else if (trialType === "ind2-charges-withdrawn")
        return ["indictible-2", "guilty-withdrawn"]
    else if (trialType === "ind2-trial")
        return ["indictible-2", "contested-trial"]
    else if (trialType === "sc-guilty")
        return ["summary-convictions", "guilty"]
    else if (trialType === "sc-charges-withdrawn")
        return ["summary-convictions", "guilty-withdrawn"]
    else if (trialType === "sc-trial")
        return ["summary-convictions", "contested-trial"]

    return ["indictible-1", "contested-trial"]
}