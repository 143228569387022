// Generated code
export const OFFENCES_LOOKUP = new Map([
    ["Acquire - Import or Possess Firearm with no FAC Certificate", ["91, 93, 94, 96, 105, 106, 107, 117.01", ["indictable-1", "summary-convictions"]]],
    ["Aggressive Panhandling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Aid and Abet a Person to Enter Canada Illegally", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Alien Smuggling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Anal Intercourse", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Application for Peace Bond (Section 810)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Assault", ["266", ["indictable-1", "summary-convictions"]]],
    ["Attempt Break & Enter into Non-dwelling", ["463(c)", ["summary-convictions"]]],
    ["Attempt Fraud Under", ["463(c)", ["summary-convictions"]]],
    ["Attempt Fraud Under", ["463(c)", ["summary-convictions"]]],
    ["Attempt Mischief", ["463(c)", ["summary-convictions"]]],
    ["Attempt Obstruct Police", ["463(c)", ["summary-convictions"]]],
    ["Attempt theft under", [" 334(b)", ["summary-convictions"]]],
    ["Attempt to Escape Custody", ["463(c)", ["summary-convictions"]]],
    ["Attempt Use Stolen Credit Card", ["463(c)", ["summary-convictions"]]],
    ["Bestiality", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Christopher's Law", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Disposition", ["137 YCJA", ["summary-convictions"]]],
    ["Breach of Identification", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Peace Bond", ["811", ["indictable-1", "summary-convictions"]]],
    ["Breach of Prohibition Order", ["733.1(a)(b)", ["indictable-1", "summary-convictions"]]],
    ["Breach of Recognizance", ["811", ["indictable-1", "summary-convictions"]]],
    ["Breach of Section 46 Family Law Act Restraining Order", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of the Occupational Health and Safety Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Undertaking", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Weapons Prohibition Order", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Break & Enter into Non-dwelling and Theft", ["348(1)(b),(e)", ["indictable-1", "summary-convictions"]]],
    ["Break & Enter into Non-dwelling with Intent", ["348(1)(b),(e)", ["indictable-1", "summary-convictions"]]],
    ["Break and Enter into Non-Dwelling", ["348(1)(b),(e)", ["indictable-1", "summary-convictions"]]],
    ["Break Enter & Commit into Non-dwelling", ["348(1)(b),(e)", ["indictable-1", "summary-convictions"]]],
    ["Break Out of Non-dwelling", ["348(1)(b),(e)", ["indictable-1", "summary-convictions"]]],
    ["Buggery", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Careless Driving", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Careless Storage of Ammunition", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Careless Storage of Firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Careless Use of Firearm", ["86(3)", ["indictable-1", "summary-convictions"]]],
    ["Carry Concealed Weapon", ["90", ["indictable-1", "summary-convictions"]]],
    ["Cause a Disturbance", ["175(1)", ["summary-convictions"]]],
    ["Charge criminal interest rate", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Communicate for the Purpose of Prostitution", ["213(1.1)", ["summary-convictions"]]],
    ["Conspiracy to Assist Persons to Enter USA Illegally", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit Fraud Under", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit a Summary Conviction Offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit Alien Smuggling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit breach of probation", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit Break and Enter into Non-Dwelling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit mischief", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Escape Custody", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Evade Duty", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Possess Controlled Substance", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Possess Prohibited Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Possess Smuggled Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to possess spirits unlawfully imported into Canada", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Possess Stolen Property under $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Promote Hatred", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Sell Illegal Cigarettes", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Sell Smuggled Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Sell Telecommunications", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Smuggle Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Use Forged Documents", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Consume Liquor Other Than Licensed Premises", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Contempt of Court", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Contravene or fail to comply with provisions of IRPA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Contravene provisions of Section 126 or 127 of IRPA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Contravention of Food and Drug Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Convey Alcoholic Beverage Within or From Controlled Access Area", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Copyright Act Charges", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Corrupting Morals", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Counsel Public Mischief", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Counsel to Commit Assault", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Counseling to Breach Recognizance", ["22", ["summary-convictions"]]],
    ["Counseling to Commit an Indictable Offence of Theft Under", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Criminal Charges under Child, Youth and Family Services Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Criminal Harassment", ["264(3)", ["indictable-1", "summary-convictions"]]],
    ["Dangerous Driving", ["320.13(1)", ["indictable-1", "summary-convictions"]]],
    ["Dangerous operation of motor vehicle while street racing", ["320.13(1)", ["indictable-1", "summary-convictions"]]],
    ["Defacing a Firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Defacing Coin", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Delivery of Restricted Weapon without a Permit", ["91, 93, 94, 96, 105, 106, 107, 117.01", ["indictable-1", "summary-convictions"]]],
    ["Destroy Firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Detain Child Contrary to CYFSA Order", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Disobey Court Order", ["127(1)(a)(b)", ["indictable-1", "summary-convictions"]]],
    ["Disturb Worship", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Double Doctoring", ["4(2) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Drinking under age", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive Disqualified", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive Motorcycle without Helmet", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive No Plates (HTA)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive Suspended", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive without Insurance", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive without Licence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Eluding examination or Inquiry (Immigration Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Engage Police Pursuit", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Engaging in Prize Fight", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Enter Premises - Prohibited", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Escape Custody", ["146", ["indictable-1"]]],
    ["Evade Duty", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Expose genitals to under 14", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Appear", ["145(2-5)", ["indictable-1", "summary-convictions"]]],
    ["Fail to Apply for Owners Permit", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to change name/address on license", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Comply", ["145(2-5)", ["indictable-1", "summary-convictions"]]],
    ["Fail to comply with order under Sex Offender Information Registration Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to comply with Section 105 re: weapons business", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Drive in Marked Lane", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Give Identification", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to keep records. Section 60 HTA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Leave Premise (TPA)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Remain", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Report", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Report (Christopher Law)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Stop", ["320.16", ["indictable-1", "summary-convictions"]]],
    ["Fail to Stop for Police", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Surrender Insurance Card", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to Surrender Permit", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Failure to Disclose Previous Prescription", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Failure to Surrender Found Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["False Fire Alarm", ["437", ["indictable-1", "summary-convictions"]]],
    ["False Pretences Under", [" 362(2)(b)", ["indictable-1", "summary-convictions"]]],
    ["False Statement on FAC", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["False Statements (Employment Insurance)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["False Statements Under", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Falsification of Documents", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Flight in Motor Vehicle", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Forcible Entry", ["73", ["indictable-1", "summary-convictions"]]],
    ["Forge or Falsify Credit Card", ["342", ["indictable-1", "summary-convictions"]]],
    ["Forgery", ["367", ["summary-convictions"]]],
    ["Found in Common Bawdy House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Found in Gaming House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fraud Accommodation", ["364", ["summary-convictions"]]],
    ["Fraud Telecommunications Under $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fraud Transportation (re Obtaining)", ["393(3)", ["summary-convictions"]]],
    ["Fraud Transportation (re Collections)", ["393 (1) & (2)", ["indictable-1"]]],
    ["Fraud Under", ["380(1)(b)", ["indictable-1", "summary-convictions"]]],
    ["Fraudulent use of a credit card", ["342", ["indictable-1", "summary-convictions"]]],
    ["Harassing Phone Calls", ["372(3)", ["indictable-1", "summary-convictions"]]],
    ["Holding Out (Travel Industry Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Hunting without License (POA)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Hunting without Permit (Migratory Bird Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Illegal Entry (Immigration)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Illegal Possession of Cigarettes", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Illegally provide/sell Liquor", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Improper Left Turn. Section 141(6) HTA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Indecent Act", ["173(1)", ["indictable-1", "summary-convictions"]]],
    ["Indecent Exposure", ["175(1)(b)", ["summary-convictions"]]],
    ["Inmate Common Bawdy House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Interfere with Transportation Facility (Railway Safety Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Interfering with Police radio communication (Radio Communication Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Intimidation", ["423(1)", ["indictable-1", "summary-convictions"]]],
    ["Joyriding", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Keep Cockpit", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Knowingly provide false or misleading information under Sex Offender Information Registration Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Laundering Proceeds of Crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Laundering Proceeds of Drugs", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Laundering Proceeds of Narcotics", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Loitering", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Mailing Obscene Matters", ["168", ["indictable-1", "summary-convictions"]]],
    ["Make - Possess or Sell Obscene Material", ["163 (1)", ["indictable-1", "summary-convictions"]]],
    ["Making False Statements Under $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Making, having or dealing in instruments for forging or falsifying credit cards", ["342", ["indictable-1", "summary-convictions"]]],
    ["Mischief Over", ["430(3)", ["indictable-1", "summary-convictions"]]],
    ["Mischief to data", ["430(5)", ["indictable-1", "summary-convictions"]]],
    ["Minor Have Liquor", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Mischief Under", ["430(4)", ["indictable-1", "summary-convictions"]]],
    ["Misuse of plate", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["No license to operate vehicle business. Section 59(1) HTA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["No Validation Permit", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Obscene Phone calls", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Obstruct Child Care Worker/Peace Officer", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Obstruct Justice - Indemnify Surety", ["139(1)", ["indictable-1", "summary-convictions"]]],
    ["Obstruct Police", ["129", ["indictable-1", "summary-convictions"]]],
    ["Occupant in Motor Vehicle with Prohibited Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Offence of Truancy", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Offer for Sale and Infringing Copy. Section 42(1)(b) Copyright Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Open Liquor Readily Available", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Open Mail (Canada Postal Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Operate Motor Vehicle no Helmet", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Operate Unsafe Vehicle", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Passenger of Stolen Vehicle", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Passing off other Wares or Services", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Permit Operation No Insurance", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Permit Premises to be Used as Common Bawdy House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Permit Premises to be Used as Gaming House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Personate Police", ["130", ["indictable-1", "summary-convictions"]]],
    ["Personation", ["403", ["indictable-1", "summary-convictions"]]],
    ["Personation at Examination", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Point Firearm", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possess Contraband Liquor", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possess - deal in or use documents in contravention of IRPA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possess Illegal License - HTA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possess - sell or offer for sale a device to obtain computer service", ["342.2(1)", ["indictable-1", "summary-convictions"]]],
    ["Possess Smuggled Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Burglary Tools", ["351(1)", ["indictable-1"]]],
    ["Possession Cocaine or Heroin", ["4(3) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Possession Concealed Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Contrary to Order Section 117.01", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Counterfeit Credit Card", ["342", ["indictable-1", "summary-convictions"]]],
    ["Possession Firearm at Unauthorized Place", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possession Forged Document", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Forgery Instruments", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Illegally Imported Spirits", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Imitation Firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Licence While Prohibited", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Narcotic (excluding cocaine and heroin)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of credit card data", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of firearm - ammunition or explosive while prohibited", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of Firearm With Serial Number Removed", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of More Than 1000 Unmarked Cigarettes", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of Unmarked Cigarettes for Purpose of Sale", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Offensive Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Prohibited Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Property Obtained by Crime for the Purpose of Trafficking Under $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Property Obtained by Crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Restricted Drug", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Restricted Weapon", ["91, 93, 94, 96, 105, 106, 107, 117.01", ["indictable-1", "summary-convictions"]]],
    ["Possession Slug for Coin Device", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Stolen Credit Cards", ["342", ["indictable-1", "summary-convictions"]]],
    ["Possession Substance Schedule 1", ["4(3) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Possession Substance Schedule 2", ["8 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Possession Substance Schedule 3", ["4(6) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Possession Under", [" 355(b)", ["indictable-1", "summary-convictions"]]],
    ["Possession Unregistered Restricted Weapon", ["91, 93, 94, 96, 105, 106, 107, 117.01", ["indictable-1", "summary-convictions"]]],
    ["Possession Unregistered Restricted Weapon in Motor Vehicle", ["91, 93, 94, 96, 105, 106, 107, 117.01", ["indictable-1", "summary-convictions"]]],
    ["Possession Weapon while Prohibited", ["91, 93, 94, 96, 105, 106, 107, 117.01", ["indictable-1", "summary-convictions"]]],
    ["Practicing Under Mortgage Brokers Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Printing, publishing, distributing of voyeuristic recordings", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Promote Hatred", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Prowl by night", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Public Intoxication", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Public Mischief", ["140(2)", ["indictable-1", "summary-convictions"]]],
    ["Remain in Canada by Improper Means (Immigration Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Remove Serial Number from Firearms", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Resist Arrest", ["270(2)", ["indictable-1", "summary-convictions"]]],
    ["Section 810.1 Recognizance where fear of sexual offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Section 810.2 Recognizance where fear of serious personal injury offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Seek Substance From Practitioner", ["4(2) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Sell an Infringing Copy. Section 42(1)(b) Copyright Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Sell Smuggled Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Smuggle Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Smuggling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Smuggling Tobacco", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Speeding", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Stalking", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Start from Stop - Not in Safety", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Take Auto without Consent", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Tampering with Vehicle Identification Number", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Tax Evasion", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft by conversion Under $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft of Credit Cards", ["342", ["indictable-1", "summary-convictions"]]],
    ["Theft of Mail", ["356", ["indictable-1"]]],
    ["Theft of Telecommunications Under $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft Under", [" 334(b)", ["summary-convictions"]]],
    ["Threaten property damage", ["264.1(1)", ["indictable-1", "summary-convictions"]]],
    ["Threatening (Section810)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Threatening to kill an animal", ["264.1(1)", ["indictable-1", "summary-convictions"]]],
    ["Throw an offensive volatile substance", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking Credit Card Data", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking Property Obtained by Crime Under $5.000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Transfer or manufacture weapon without authorization", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Transport to Common Bawdy House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trespassing at Night", ["177", ["summary-convictions"]]],
    ["Trespassing to Property", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unauthorized consultation, matching, disclosure and use of information under Sex Offender Information Registration Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unauthorized person exercising function or performing duty under Sex Offender Information Registration Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unauthorized Use of Computer", ["342.1(1)", ["indictable-1", "summary-convictions"]]],
    ["Unfair Practice (Business Practices Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unlawful Assembly", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unlawful use of military uniforms or certificates", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unlawfully at Large", ["145(1)", ["indictable-1", "summary-convictions"]]],
    ["Unlawfully in Dwelling", ["349", ["indictable-1", "summary-convictions"]]],
    ["Use Counterfeit Credit Card", ["342", ["indictable-1", "summary-convictions"]]],
    ["Use Plates not Authorized", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Use Stolen Credit Card", ["342", ["indictable-1", "summary-convictions"]]],
    ["Using or Operating a Waste Disposal site without Certificate of Approval or Provisional Certificate of Approval (E.P Act)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Uttering Forged Document", ["368", ["indictable-1", "summary-convictions"]]],
    ["Vagrancy", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Vehicle with Liquor Readily Available", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Bankruptcy and Insolvency Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Federal Statute", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Highway Traffic Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Immigration Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Mortgage Brokers Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Municipal Bylaw", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Ontario Works/Ontario Disability Support Plan Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Provincial Offences Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Retail Sales Tax Act (P.O.A.)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Violation of Workplace Safety Insurance Act", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Voyeurism", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Watch and Beset", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Weapons at Public Meeting", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Weapons Dangerous", ["88", ["indictable-1", "summary-convictions"]]],
    ["Willful damage", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Abduction", ["280, 281", ["indictable-2", "summary-convictions"]]],
    ["Assault Bodily Harm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Assault Police", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Assault Resist Arrest", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Assault with a Weapon", ["267", ["indictable-1", "summary-convictions"]]],
    ["Attempt to Disarm Peace Officer", ["463(c)", ["summary-convictions"]]],
    ["Attempt to Kill or Wound Animal", ["463(c)", ["summary-convictions"]]],
    ["Breach of Deportation Order", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Probation", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Bringing Proceeds of Crime into Canada", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Care and Control Over 80", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit Theft Under", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Cruelty to Animals", ["445.1", ["indictable-1", "summary-convictions"]]],
    ["Disarm Peace Officer", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Drive Over 80", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Forcible Confinement", ["279(2)", ["indictable-1", "summary-convictions"]]],
    ["Illegal Sale of Cigarettes", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Impaired Care and Control", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Impaired Driving", ["320.14(1)", ["indictable-1", "summary-convictions"]]],
    ["Import/Export Substance Schedule 3", ["6(3)(b) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Import/Export Substance Schedule 4", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Interfere with Youth Disposition", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Injuring or Endangering other Animals", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Kill an Animal (Cattle Excluded)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Making Automatic Weapons", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Manufacturing Controlled Drug", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Manufacturing Restricted Drug", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Obtain substance or authorization for substance - Schedule 4", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of Articles Stolen from the Mail", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of Firearm with Ammunition", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possession of Firearm without Licence", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possession of stolen mail", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Substance Schedule 3 for the Purpose", ["5(3)(b) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Possession Substance Schedule 4 for the Purpose", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Weapon Obtained by Crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Production Schedule 3 & 4", ["7(2)(c) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Refuse Blood Sample", ["320.15, 320.19(1)", ["indictable-1", "summary-convictions"]]],
    ["Refuse Breathalyzer", ["320.15, 320.19(1)", ["indictable-1", "summary-convictions"]]],
    ["Sell Instrument for Drug Use", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Sell Prohibited Weapon", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft of Motor Vehicle", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Threatening Death", ["264.1(1)", ["indictable-1", "summary-convictions"]]],
    ["Threatening death/bodily harm", ["264.1(1)", ["indictable-1", "summary-convictions"]]],
    ["Trafficking Controlled Drug Schedule 3 & 4", ["5(3)(b) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Trafficking Restricted Drug Schedule 3 & 4", ["5(3)(b) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Trafficking Substance Schedule 3", ["5(3)(b) CDSA", ["indictable-1", "summary-convictions"]]],
    ["Trafficking Substance Schedule 4", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unlawful Confinement", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Unlawfully Causing Bodily Harm", ["267", ["indictable-1", "summary-convictions"]]],
    ["Accept or Offer Secret Commission", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Acknowledge Bail Under False Name", ["405", ["indictable-1"]]],
    ["Administer Stupefying Substance", ["246(b)", ["indictable-1"]]],
    ["Aggravated Assault", ["268(2)", ["indictable-2"]]],
    ["Alien Smuggling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Attempt Arson", ["463 (a)", ["indictable-2"]]],
    ["Attempt Break and Enter into dwelling", ["463(b)", ["indictable-1"]]],
    ["Attempt Fraud Over", ["463(b)", ["indictable-1"]]],
    ["Attempt Robbery", ["463(b)", ["indictable-1"]]],
    ["Attempt Sexual Assault", ["463(b)", ["indictable-1"]]],
    ["Attempt theft over", [" 334(b)", ["summary-convictions"]]],
    ["Attempt to Commit an Indictable Offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Attempt to procure a miscarriage", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Betting/Bookmaking", ["201(1)", ["indictable-1"]]],
    ["Bigamy", ["291(1)", ["indictable-1"]]],
    ["Book and documents falsification", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Breach of Duty re: possession of explosives", ["82(1)", ["indictable-1", "summary-convictions"]]],
    ["Breach of Trust", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Break & Enter into Dwelling and Theft", ["348(1)(c),(d)", ["indictable-1"]]],
    ["Break & Enter into Dwelling with intent", ["348(1)(c),(d)", ["indictable-1"]]],
    ["Break and Enter into Dwelling", ["348(1)(c),(d)", ["indictable-1"]]],
    ["Break Enter & Commit into Dwelling", ["348(1)(c),(d)", ["indictable-1"]]],
    ["Break Out of Dwelling", ["348(1)(c),(d)", ["indictable-1"]]],
    ["Bribery", ["119(1), 120", ["indictable-1"]]],
    ["Carry Flammable Substance", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Causing bodily harm by criminal negligence (Street Racing)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Cheat at Play", ["209", ["indictable-1", "summary-convictions"]]],
    ["Choking 246(a)", ["246(a)", ["indictable-1"]]],
    ["Common Nuisance", ["180(1)", ["indictable-1", "summary-convictions"]]],
    ["Compounding Indictable Offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Concealing Body of Child", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conduct pyramid scheme", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit Fraud Over", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Commit Break and Enter into Dwelling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Possess Stolen Property over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to Prosecute a Person Knowing that Person did not Commit the Offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Control Money Related to a Device for Gambling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Corruptly Take Reward for Recovery of Goods", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Counsel to misrepresent or withhold material facts contrary to section 126 of IRPA", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Counseling to Commit a Type I Indictable Offence", ["22", ["indictable-1"]]],
    ["Counseling to Commit a Type II Indictable Offence", ["22", ["indictable-2"]]],
    ["Counseling to Commit Bodily Harm", ["22", ["indictable-2"]]],
    ["Counseling to Commit Robbery", ["22", ["indictable-2"]]],
    ["Criminal Negligence Causing Bodily Harm", ["221", ["indictable-2"]]],
    ["Cultivation of Narcotic/Controlled Substance", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Dangerous Driving Causing Bodily Harm", ["320.13(2), 320.2", ["indictable-1", "summary-convictions"]]],
    ["Dangerous operation of motor vehicle while street racing - causing bodily harm", ["320.13(1)", ["indictable-1", "summary-convictions"]]],
    ["Defamatory Libel", ["300, 301, 302", ["indictable-1"]]],
    ["Disguise with intent", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Disobey Court Order", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Disposal of Property to Defraud Creditors", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Endangering Life", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Extortion", ["346", ["indictable-2"]]],
    ["Fabricating Evidence", ["137", ["indictable-2"]]],
    ["Fail to Remain Causing Bodily Harm or Death", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["False Messages", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["False Pretences Over", [" 362(2)(a)", ["indictable-1", "summary-convictions"]]],
    ["False Prospectus", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["False Statements Over", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Flight in Motor Vehicle Causing Bodily Harm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fraud Over", ["380(1)(a)", ["indictable-1"]]],
    ["Fraud Telecommunications Over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fraudulent concealment", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fraudulent use of certificate of citizenship", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Give Contradictory Evidence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["High-jacking", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Householder permitting sexual activity", ["171", ["indictable-2"]]],
    ["Illegal Sale of Cigarettes", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Illegal use of firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Impaired Driving Causing Bodily Harm", ["320.14(2)", ["indictable-1", "summary-convictions"]]],
    ["Import/Export Substance Schedule 2", ["11 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Import/Export Substance Schedule1", ["6(3)(a) CDSA", ["indictable-2"]]],
    ["Import Property Obtained by Crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Importing Narcotics", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Indecently interfere with or offer and indignity to human remains", ["182", ["indictable-1", "summary-convictions"]]],
    ["Intent to Cause Explosion", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Intercept Private Communication", ["184", ["indictable-1"]]],
    ["Interfere with Youth Disposition", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Issue or use a misleading receipt", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Keeping a Device for Gambling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Keeping Common Bawdy House", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Keeping Gaming House", ["201(1)", ["indictable-1"]]],
    ["Kill Cattle", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Live on Avails of Prostitution", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Lottery Scheming", ["206(1)", ["indictable-1", "summary-convictions"]]],
    ["Make Counterfeit Money", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Make, sell or possess a counterfeit mark or stamp", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Making False Statements Over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Mischief and Endangering Life", ["430(2)", ["indictable-1"]]],
    ["Neglect to perform a duty to the burial of human remains", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Neglect to Obtain Assistance in Child-Birth", ["238", ["indictable-2"]]],
    ["Obstruct Clergy", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Obstruct Justice - Bride, Threaten, or Influence Juror or Witness", ["139(2)", ["indictable-1"]]],
    ["Occupant Injuring Building", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Overcoming Resistance", ["246(a)", ["indictable-1"]]],
    ["Participate in Riot", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Perjury", ["132", ["indictable-2"]]],
    ["Possess Explosive Substance", ["82(1)", ["indictable-1", "summary-convictions"]]],
    ["Possess or Make Instruments for Counterfeiting", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Cocaine or Heroin for the purpose of trafficking", ["5(3)(a) CDSA", ["indictable-2"]]],
    ["Possession Coin Operated Break-In Instrument", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Controlled Drug for the Purpose of Trafficking", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Counterfeit Money", ["450", ["indictable-2"]]],
    ["Possession explosive substance without lawful excuse", ["82(1)", ["indictable-1", "summary-convictions"]]],
    ["Possession for the Purpose of Weapon Trafficking", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Incendiary Material", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Narcotic for the Purpose of Trafficking (excluding cocaine and heroin)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of Articles Stolen from the Mail", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of Firearm While Committing Offence", ["85", ["indictable-1"]]],
    ["Possession of Firearm without Licence", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possession of Revenue Paper", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of stolen mail", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Over", [" 355(a)", ["indictable-1", "summary-convictions"]]],
    ["Possession Prohibited Firearm Knowing Unauthorized", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possession Property Obtained by Crime Over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Property Obtained by Crime for the Purpose of Trafficking over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Restricted Drug for the Purpose of Trafficking", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Substance Schedule 1 for the Purpose", ["5(3)(a) CDSA", ["indictable-2"]]],
    ["Possession Substance Schedule 2 for the Purpose", ["13 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Possession telecommunication device or facility", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession Unregistered Restricted Weapon", ["86, 87, 95, 102", ["indictable-1", "summary-convictions"]]],
    ["Possession Weapon while Committing Offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Prison Breach", ["144", ["indictable-1", "summary-convictions"]]],
    ["Procure a Miscarriage", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Procure Feigned Marriage", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Procuring", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Produce False Evidence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Production Schedule 1", ["7(2)(a) CDSA", ["indictable-2"]]],
    ["Production Schedule 2", ["12 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Rescue", ["147", ["indictable-1", "summary-convictions"]]],
    ["Permitting Escape", ["146", ["indictable-1"]]],
    ["Robbery", ["344", ["indictable-2"]]],
    ["Sabotage", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Sell Firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Sell - offer for sale - or possess automobile master key", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Sign - use or offer for use false affidavit", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Take/Attempt to take Reward", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft by conversion Over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft of Cattle", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Theft Over", [" 334(a)", ["indictable-1", "summary-convictions"]]],
    ["Torture", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Traffic Cocaine or Heroin", ["5(3)(a) CDSA", ["indictable-2"]]],
    ["Trafficking a Substance Held Out to be Cocaine", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking Controlled Drug Schedule 1", ["5(3)(a) CDSA", ["indictable-2"]]],
    ["Trafficking Controlled Drug Schedule 2", ["10 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Trafficking in Persons", ["18 279.01/279.011", ["indictable-2"]]],
    ["Trafficking in Property Obtained by Crime Over $5,000", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking Narcotic", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking Restricted Drug Schedule 1 & 2", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking Substance Schedule 1", ["5(3)(a) CDSA", ["indictable-2"]]],
    ["Trafficking Substance Schedule 2", ["10 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Traps likely to Cause Bodily Harm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Use Counterfeit Money", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Use Firearm while Committing", ["85", ["indictable-1"]]],
    ["Use Imitation Firearm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Use mail to Defraud", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Use Weapon while Committing Offence", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Using Explosives", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Utter Bomb Threat", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Utter Counterfeit Money", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Uttering Forged Passport", ["368", ["indictable-1", "summary-convictions"]]],
    ["Weapons Trafficking", ["99, 100", ["indictable-1"]]],
    ["Willfully damage dwelling house/bldg.", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Abandoning child", ["218", ["indictable-1", "summary-convictions"]]],
    ["Abduction in contravention of custody order", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Abduction of person under 16", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Accessing child pornography", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Accessory after fact to murder, attempt murder, counselling murder, conspiracy to commit murder", ["22, 465(1)(a)", ["indictable-2"]]],
    ["Administer noxious substance - intent to endanger life or cause bodily harm", ["245(1)(a)", ["indictable-1"]]],
    ["Aggravated sexual assault", ["273(2)", ["indictable-1"]]],
    ["Aggravated sexual assault - firearm used", ["272", ["indictable-1"]]],
    ["Aggravated sexual assault - use of restricted or prohibited firearm, or involves organized crime", ["272", ["indictable-1"]]],
    ["Arson", ["434", ["indictable-2"]]],
    ["Attempt murder - firearm used", ["239(1)(b)", ["indictable-2"]]],
    ["Attempt murder - use of restricted or prohibited firearm, or involves organized crime and is second or subsequent offence", ["239(1)(b)", ["indictable-2"]]],
    ["Attempt murder - use of restricted or prohibited firearm or involves organized crime and is first offence", ["239(1)(b)", ["indictable-2"]]],
    ["Attempt to procure sexual activity from minor (under 16)", ["463(b)", ["indictable-1"]]],
    ["Attempt to procure sexual activity from minor (under 18)", ["463(b)", ["indictable-1"]]],
    ["Attempt abduction of person under 16", ["463(b)", ["indictable-1"]]],
    ["Buggery [historical offence]", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Commission of offence for criminal organization", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Commit indictable offence for terrorist group", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to break into dwelling", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit aggravated assault", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit arson", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit arson (danger to life)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit robbery", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit fraud over", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit terrorism", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to commit theft over", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to import or traffic narcotics", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to sell prohibited weapons (firearms)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to sell prohibited weapons (not firearms)", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Conspiracy to use forged documents", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Corrupting children", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Criminal negligence causing death", ["220(1)(2)", ["indictable-2"]]],
    ["Criminal negligence causing death - firearm used", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Cultivation - cannabis (large-scale grow-op)*", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Dangerous driving causing death", ["320.13(3), 320.21", ["indictable-2"]]],
    ["Dangerous driving causing death - street-racing", ["320.13(3), 320.21", ["indictable-2"]]],
    ["Discharge firearm with intent", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Discharge firearm with intent - use of restricted or prohibited firearm, or involves organized crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Endangering safety of aircraft", ["76, 77, 78", ["indictable-1"]]],
    ["Extortion*", ["346", ["indictable-2"]]],
    ["Extortion - firearm used", ["346", ["indictable-2"]]],
    ["Extortion - use of restricted or prohibited firearm, or involves organized crime", ["346", ["indictable-2"]]],
    ["Facilitating terrorism", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Fail to provide necessities of life", ["215(1)", ["indictable-1", "summary-convictions"]]],
    ["Failing to remain causing death", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Financing terrorism", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Flight in motor vehicle causing death", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Gross indecency", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Harbour or conceal person - terrorist activity", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Hoax regarding terrorist activity", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Hostage taking", ["279.1(1)(b)", ["indictable-2"]]],
    ["Hostage taking - firearm used", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Hostage taking - use of restricted or prohibited firearm, or involves", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["organized crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Impaired driving/driving over 80 causing death", ["320.14(3)", ["indictable-2"]]],
    ["Importing child pornography", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Importing narcotics - Schedule I", ["6(3)(a) CDSA", ["indictable-2"]]],
    ["Importing narcotics - Schedule II*", ["11 Cannabis Act", ["indictable-1", "summary-convictions"]]],
    ["Incest", ["155(2)", ["indictable-2"]]],
    ["Indecent assault on female [historical offence]", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Indecent assault on male [historical offence]", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Infanticide", ["237", ["indictable-2", "summary-convictions"]]],
    ["Instructing offence for criminal organization", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Instructing to carry out terrorist activity", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Intent to cause explosion that is likely to cause death or serious bodily harm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Invitation to sexual touching", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Kidnapping", ["279", ["indictable-2"]]],
    ["Kidnapping - firearm used", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Kidnapping - use of restricted or prohibited firearm, or involves organized crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Living on avails of prostitution of person under 18", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Luring child by means of computer system", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Making available, printing, distributing, selling child pornography", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Making child pornography", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Manslaughter", ["236(b)", ["indictable-2"]]],
    ["Murder - first and second degree", ["231, 235(1)", ["indictable-2"]]],
    ["Participate in or contribute to activity of terrorist group", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Participation in activities of criminal organization", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of child pornography", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Possession of explosive substance for criminal organization", ["82(1)", ["indictable-1", "summary-convictions"]]],
    ["Possession of weapons (firearms) for purpose of trafficking*", ["99, 100", ["indictable-1"]]],
    ["Procure sexual activity from minor (under 18)", ["170", ["indictable-1"]]],
    ["Procure sexual activity from minor (under 16)", ["170", ["indictable-1"]]],
    ["Produce Schedule I or Schedule II drug (other than cannabis)*", ["7(2)(A) CDSA", ["indictable-2"]]],
    ["Rape [historical offence]", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Robbery - firearm used", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Robbery - use of restricted or prohibited firearm, or involves organized crime", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Setting trap causing death", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Sexual assault", ["271", ["indictable-1"]]],
    ["Sexual assault causing bodily harm - firearm used", ["272", ["indictable-1"]]],
    ["Sexual assault causing bodily harm - use of restricted or prohibited firearm, or involves organized crime", ["272", ["indictable-1"]]],
    ["Sexual assault with a weapon - firearm used", ["272", ["indictable-1"]]],
    ["Sexual assault with a weapon - restricted or prohibited firearm used", ["272", ["indictable-1"]]],
    ["Sexual exploitation", ["153", ["indictable-1", "summary-convictions"]]],
    ["Sexual interference", ["151", ["indictable-1", "summary-convictions"]]],
    ["Trafficking in persons - causing death", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Trafficking in persons - commit aggravated assault or aggravated sexual assault", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Using explosives with intent to cause bodily harm", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Using or possessing property for terrorist activity", ["", ["indictable-1", "indictable-2", "summary-convictions"]]],
    ["Weapons trafficking (firearms)*", ["99, 100", ["indictable-1"]]],
])

export const EXCLUSION_LIST = [
    "Abandoning child",
    "Abduction in contravention of custody order",
    "Abduction of person under 16",
    "Accessing child pornography",
    "Accessory after fact to murder, attempt murder, counselling murder, conspiracy to commit murder",
    "Administer noxious substance - intent to endanger life or cause bodily harm",
    "Aggravated sexual assault",
    "Aggravated sexual assault - firearm used",
    "Aggravated sexual assault - use of restricted or prohibited firearm, or involves organized crime",
    "Arson",
    "Attempt murder - firearm used",
    "Attempt murder - use of restricted or prohibited firearm, or involves organized crime and is second or subsequent offence",
    "Attempt murder - use of restricted or prohibited firearm or involves organized crime and is first offence",
    "Attempt to procure sexual activity from minor (under 16)",
    "Attempt to procure sexual activity from minor (under 18)",
    "Attempt abduction of person under 16",
    "Buggery [historical offence]",
    "Commission of offence for criminal organization",
    "Commit indictable offence for terrorist group",
    "Conspiracy to break into dwelling",
    "Conspiracy to commit aggravated assault",
    "Conspiracy to commit arson",
    "Conspiracy to commit arson (danger to life)",
    "Conspiracy to commit robbery",
    "Conspiracy to commit fraud over",
    "Conspiracy to commit terrorism",
    "Conspiracy to commit theft over",
    "Conspiracy to import or traffic narcotics",
    "Conspiracy to sell prohibited weapons (firearms)",
    "Conspiracy to sell prohibited weapons (not firearms)",
    "Conspiracy to use forged documents",
    "Corrupting children",
    "Criminal negligence causing death",
    "Criminal negligence causing death - firearm used",
    "Cultivation - cannabis (large-scale grow-op)*",
    "Dangerous driving causing death",
    "Dangerous driving causing death - street-racing",
    "Discharge firearm with intent",
    "Discharge firearm with intent - use of restricted or prohibited firearm, or involves organized crime",
    "Endangering safety of aircraft",
    "Extortion*",
    "Extortion - firearm used",
    "Extortion - use of restricted or prohibited firearm, or involves organized crime",
    "Facilitating terrorism",
    "Fail to provide necessities of life",
    "Failing to remain causing death",
    "Financing terrorism",
    "Flight in motor vehicle causing death",
    "Gross indecency",
    "Harbour or conceal person - terrorist activity",
    "Hoax regarding terrorist activity",
    "Hostage taking",
    "Hostage taking - firearm used",
    "Hostage taking - use of restricted or prohibited firearm, or involves",
    "organized crime",
    "Impaired driving/driving over 80 causing death",
    "Importing child pornography",
    "Importing narcotics - Schedule I",
    "Importing narcotics - Schedule II*",
    "Incest",
    "Indecent assault on female [historical offence]",
    "Indecent assault on male [historical offence]",
    "Infanticide",
    "Instructing offence for criminal organization",
    "Instructing to carry out terrorist activity",
    "Intent to cause explosion that is likely to cause death or serious bodily harm",
    "Invitation to sexual touching",
    "Kidnapping",
    "Kidnapping - firearm used",
    "Kidnapping - use of restricted or prohibited firearm, or involves organized crime",
    "Living on avails of prostitution of person under 18",
    "Luring child by means of computer system",
    "Making available, printing, distributing, selling child pornography",
    "Making child pornography",
    "Manslaughter",
    "Murder - first and second degree",
    "Participate in or contribute to activity of terrorist group",
    "Participation in activities of criminal organization",
    "Possession of child pornography",
    "Possession of explosive substance for criminal organization",
    "Possession of weapons (firearms) for purpose of trafficking*",
    "Procure sexual activity from minor (under 18)",
    "Procure sexual activity from minor (under 16)",
    "Produce Schedule I or Schedule II drug (other than cannabis)*",
    "Rape [historical offence]",
    "Robbery - firearm used",
    "Robbery - use of restricted or prohibited firearm, or involves organized crime",
    "Setting trap causing death",
    "Sexual assault",
    "Sexual assault causing bodily harm - firearm used",
    "Sexual assault causing bodily harm - use of restricted or prohibited firearm, or involves organized crime",
    "Sexual assault with a weapon - firearm used",
    "Sexual assault with a weapon - restricted or prohibited firearm used",
    "Sexual exploitation",
    "Sexual interference",
    "Trafficking in persons - causing death",
    "Trafficking in persons - commit aggravated assault or aggravated sexual assault",
    "Using explosives with intent to cause bodily harm",
    "Using or possessing property for terrorist activity",
    "Weapons trafficking (firearms)*"
]

export const OFFENCES_LOOKUP_KEYS = Array.from(OFFENCES_LOOKUP.keys())