import { useEffect, useState } from "react"
import api from '../api';

type Invoice = {
    uid: string
    title: string
    description: string,
    is_deleted: boolean
    // request_payload: any,
    // results_payload: any,
}

export function InvoiceList() {
    const [invoices, setInvoices] = useState<Array<Invoice>>([])

    useEffect(() => {
        api.get("/legal-aid/invoice").then(rsp => {
            let invoiceList: Array<Invoice> = rsp.data
            setInvoices(invoiceList.filter(i => !i.is_deleted))
        })
    }, [])

    const invoiceToElement = (invoice: Invoice) => {
        return (
            <a href={`/app/legal-aid/calculator/${invoice.uid}`} >
                <div key={invoice.uid}>
                    {/* <div>{invoice.uid}</div> */}
                    <div className="format-font-small">{invoice.title}</div>
                    <div className="format-font-subscript">{invoice.description}</div>
                </div>
            </a>
        )
    }

    return (
        <div>{invoices.map(invoiceToElement)}
        </div>
    )
}
