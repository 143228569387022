import { ChangeEvent, useState } from "react"
import "./legal-aid.css"
import { OFFENCES_LOOKUP, OFFENCES_LOOKUP_KEYS } from "./offenceTable"


type OffencesLookupProps = {
    value: string
    pleaType: string
    onChange: (offense: string) => void
    onNewOffence: (offence: string, section: string, tables: string[]) => void
    onChangePleaType: (pleaType: string) => void
    onReset: () => void
}


function OffencesLookup(props: OffencesLookupProps) {

    const [lookupValue, setLookupValue] = useState("")
    const [options, setOptions] = useState<string[]>([])

    const onLookupChange = (evt: ChangeEvent<HTMLInputElement>) => {
        props.onChange(evt.target.value)
        setLookupValue(evt.target.value)
        if (evt.target.value.length < 3) {
            setOptions([])
            props.onReset()
            return
        }

        if (OFFENCES_LOOKUP_KEYS.indexOf(evt.target.value) >= 0) {
            onClickLookupItemValue(evt.target.value)
            return
        }
        else {
            props.onReset()
        }

        let tokens = evt.target.value.replaceAll(/\s+/g, " ").split(" ")
        let newOptions = OFFENCES_LOOKUP_KEYS
        for (let t of tokens) {
            newOptions = newOptions.filter(k => k.toLowerCase().indexOf(t.toLowerCase()) >= 0)
        }

        setOptions(newOptions.slice(0, 5))
    }

    const onClickLookupItemValue = (newLookupValue: string) => {
        setLookupValue(newLookupValue)
        setOptions([])

        let lookup = OFFENCES_LOOKUP.get(newLookupValue) as (string | string[])[]
        props.onNewOffence(newLookupValue, lookup[0] as string, lookup[1] as string[])
    }

    var dropDownItems: JSX.Element[] | null = [(<div key="none">No offences found</div>)]
    if (OFFENCES_LOOKUP_KEYS.indexOf(lookupValue) >= 0) {
        dropDownItems = null
    }
    else if (options.length > 0) {
        dropDownItems = options.map(o => {
            return (
                <div key={o} className="offences-lookup-drop-down-item" onClick={() => onClickLookupItemValue(o)}>{o}</div>
            )
        })
    }

    return (
        <div className="offences-lookup-root">
            <div className="offences-lookup-title format-font-small">Name of Offence</div>
            <div className="offences-lookup-input-wrap">
                <input className="offences-lookup-input" type="text" value={props.value} onChange={onLookupChange} />
                <div className="offences-lookup-drop-down" style={{ display: lookupValue.length >= 3 ? "block" : "none" }}>
                    {dropDownItems}
                </div>
            </div>
        </div>
    )
}

export default OffencesLookup