import api from '../api';
import { useEffect, useState } from 'react';
import { FormCheckBox, FormDropDown, FormNumber, FormText, FormTextArea } from '../component/form';
import { LAWYER_TIER, SEVERITY_OF_CHARGE, getTrialType, fromTrialType, PLEA_TYPE } from './const';
import { Theme } from '../theme';
import { ImageButton, ModalPanel, TextButton } from '../component/input';
import OffencesLookup from './Offences';
import "./legal-aid.css"
import { EXCLUSION_LIST } from './offenceTable';
import { useNavigate, useParams } from 'react-router-dom';

const HALF_DAY_PROCEEDINGS = [
    "ind1-guilty", "ind1-charges-withdrawn", "sc-guilty", "sc-charges-withdrawn"
]


function Calculator() {

    const nav = useNavigate()

    // common
    const [isNorthernAreaCourt, setIsNorthernAreaCourt] = useState(false)

    // Block fees
    // const [isBlockFee, setIsBlockFee] = useState(false)
    const [blockFee, setBlockFee] = useState(0.0)
    const [blockRateCategory, setBlockRateCategory] = useState("")

    // Hourly variables
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [invoiceUid, setInvoiceUid] = useState<string | null>(null)

    const [lawyerTier, setLawyerTier] = useState("tier-1")
    const [pleaType, setPleaType] = useState("guilty")
    const [severityOfCharge, setSeverityOfCharge] = useState("indictable-1")
    const [severityOfChargeOptions, setSeverityOfChargeOptions] = useState(SEVERITY_OF_CHARGE)
    const [offenceType, setOffenceType] = useState("")
    const [offenceSection, setOffenceSection] = useState("")

    const [daysInCourt, setDaysInCourt] = useState(0)
    const [hoursInCourt, setHoursInCourt] = useState(0)

    // Ancillary Criminal Proceedings (A la cart items)
    const [judicialPretrialOcj1, setJudicialPretrialOcj1] = useState<boolean>(false)
    const [judicialPretrialOcj2, setJudicialPretrialOcj2] = useState<boolean>(false)
    const [judicialPretrialScj, setJudicialPretrialScj] = useState<boolean>(false)
    const [judicialInterimReleaseOrder, setJudicialInterimReleaseOrder] = useState<boolean>(false)
    const [judicialInterimReleaseOrder524, setJudicialInterimReleaseOrder524] = useState<boolean>(false)
    const [bailHearing524, setBailHearing524] = useState<boolean>(false)
    const [bailVariation, setBailVariation] = useState<boolean>(false)
    const [bailReviewScj, setbailReviewScj] = useState<boolean>(false)
    const [gladueSubmissions, setGladueSubmissions] = useState<boolean>(false)
    const [detentionReview, setDetentionReview] = useState<boolean>(false)
    const [charter8, setCharter8] = useState<boolean>(false)
    const [mentalHealth, setMentalHealth] = useState<boolean>(false)

    // internal vars
    const [searchOffenceType, setSearchOffenceType] = useState("")
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const deleteModalMap = new Map<string, () => void>([
        ["Delete", () => { api.delete(`legal-aid/invoice/${invoiceUidParam}`).then(rsp => { nav("/app/legal-aid/calculator"); window.location.reload() }); setShowDeleteModal(false) }],
        ["Cancel", () => { setShowDeleteModal(false) }],
    ])

    const onSubmit = () => {
        api.post("legal-aid/invoice", {
            uid: invoiceUid,
            title: title,
            desc: description,
            lawyer_tier: lawyerTier,
            is_northern_court: isNorthernAreaCourt,
            offence_type: offenceType,
            trial_type: getTrialType(severityOfCharge, pleaType),
            effective_day_units: (HALF_DAY_PROCEEDINGS.indexOf(getTrialType(severityOfCharge, pleaType)) >= 0) ? daysInCourt * 2 : daysInCourt,
            hours_in_court: hoursInCourt,

            judicial_pre_trial_ocj1: judicialPretrialOcj1,
            judicial_pre_trial_ocj2: judicialPretrialOcj2,
            judicial_pre_trial_scj: judicialPretrialScj,
            judicial_interim_release_order: judicialInterimReleaseOrder,
            judicial_interim_release_order_524: judicialInterimReleaseOrder524,
            bail_hearing_524: bailHearing524,
            bail_variation: bailVariation,
            bail_review_scj: bailReviewScj,
            detention_review: detentionReview,
            gladue_submissions: gladueSubmissions,
            charter8: charter8,
            mental_health: mentalHealth,
        })
            .then(rsp => {
                console.log(rsp)
                setInvoiceUid(rsp.data.invoice_uid)
                window.alert(
                    `Offence: ${offenceType}\n` +
                    `Plea type: ${PLEA_TYPE.get(pleaType)}\n` +
                    `Severity of Charge: ${SEVERITY_OF_CHARGE.get(severityOfCharge)}\n` +
                    `Base Hours: ${rsp.data.base_hours}h\n` +
                    ((rsp.data.hours_in_court > 0) ? `Time spent at Trial (No Maximum): ${rsp.data.hours_in_court}h\n` : "") +
                    `Effective Full Days in Court : ${daysInCourt}\n` +
                    `Hours in Attendance at Court : ${hoursInCourt}\n` +
                    ((rsp.data.per_day_bonus > 0) ? `Preparation for each full day after the first: ${rsp.data.per_day_bonus}h\n` : "") +
                    ((rsp.data.ancillary_bonus) ? `Ancillary Hours: ${rsp.data.ancillary_bonus}h\n` : "") +
                    `Rate: $${rsp.data.lawyer_rate}/hour\n` +
                    `Total: $${rsp.data.grand_total}`
                    + (Number(rsp.data.grand_total) > 20000 ? "\n\nBecause the total bill is greater than $20,000 we advise you to file a big case management application." : "")
                )
            })
            .catch(err => console.log(err))
    }

    // Load
    const { invoiceUidParam } = useParams()
    if (invoiceUidParam && invoiceUidParam !== invoiceUid) {
        api.get(`legal-aid/invoice/${invoiceUidParam}`).then(rsp => {
            const invoiceData = rsp.data.request_payload.request
            setInvoiceUid(invoiceUidParam)
            setTitle(invoiceData.title)
            setDescription(invoiceData.desc)
            setLawyerTier(invoiceData.lawyer_tier)
            setIsNorthernAreaCourt(invoiceData.is_northern_court)
            let [_severityOfCharge, _pleaType] = fromTrialType(invoiceData.trial_type)
            setPleaType(_pleaType)
            setSeverityOfCharge(_severityOfCharge)
            setOffenceType(invoiceData.offence_type)
            setSearchOffenceType(invoiceData.offence_type)

            if (HALF_DAY_PROCEEDINGS.indexOf(invoiceData.trial_type) >= 0)
                setDaysInCourt(invoiceData.effective_day_units / 2)
            else
                setDaysInCourt(invoiceData.effective_day_units)

            setHoursInCourt(invoiceData.hours_in_court || 0)
            setJudicialPretrialOcj1(invoiceData.judicial_interim_release_order)
            setJudicialPretrialOcj2(invoiceData.judicial_interim_release_order_524)
            setJudicialPretrialScj(invoiceData.judicial_pre_trial_scj)
            setJudicialInterimReleaseOrder(invoiceData.judicial_interim_release_order)
            setJudicialInterimReleaseOrder524(invoiceData.judicial_interim_release_order_524)
            setBailHearing524(invoiceData.bail_hearing_524)
            setBailVariation(invoiceData.bail_variation)
            setbailReviewScj(invoiceData.bail_review_scj)
            setGladueSubmissions(invoiceData.gladue_submissions)
            setDetentionReview(invoiceData.detention_review)
            setCharter8(invoiceData.charter8)
            setMentalHealth(invoiceData.mental_health)
        })
    }

    const resetState = () => {
        setInvoiceUid(null)
        setTitle("")
        setDescription("")
        setLawyerTier("tier-1")
        setSearchOffenceType("")
        setOffenceType("")
        setPleaType("guilty")
        setSeverityOfCharge("indictible-1")

        setDaysInCourt(0)
        setHoursInCourt(0)

        setJudicialPretrialOcj1(false)
        setJudicialPretrialOcj2(false)
        setJudicialPretrialScj(false)
        setJudicialInterimReleaseOrder(false)
        setBailHearing524(false)
        setBailVariation(false)
        setBailHearing524(false)
        setGladueSubmissions(false)
        setDetentionReview(false)
        setCharter8(false)

        nav("/app/legal-aid/calculator")
    }

    const ancillaryElement = (
        <div className="calculator-ancillary" >
            {/* Ancillary Criminal Proceedings */}
            <FormCheckBox label="Judicial Pre-trial OCJ #1" id="judicial-pre-trial-ocj1"
                value={judicialPretrialOcj1} onChange={setJudicialPretrialOcj1} />

            <FormCheckBox label="Judicial Pre-trial OCJ #2" id="judicial-pre-trial-ocj2"
                value={judicialPretrialOcj2} onChange={setJudicialPretrialOcj2} />

            <FormCheckBox label="Judicial Pre-trial SCJ" id="judicial-pre-trial-ocj3"
                value={judicialPretrialScj} onChange={setJudicialPretrialScj} />

            <FormCheckBox label="Judicial Interim Release" id="judicial-interim-release-order"
                value={judicialInterimReleaseOrder} onChange={setJudicialInterimReleaseOrder} />

            <FormCheckBox label="Judicial Interim Release (524 Bail)" id="judicial-interim-release-order-524"
                value={judicialInterimReleaseOrder524} onChange={setJudicialInterimReleaseOrder524} />

            <FormCheckBox label="Bail Hearing (No s. 524)" id="bail-hearing-524"
                value={bailHearing524} onChange={setBailHearing524} />

            <FormCheckBox label="Bail Variation" id="bail-variation"
                value={bailVariation} onChange={setBailVariation} />

            <FormCheckBox label="Bail Review (SCJ)" id="bail-review-scj"
                value={bailReviewScj} onChange={setbailReviewScj} />

            <FormCheckBox label="Detention Review (&quot;Myers&quot;)" id="detention-review"
                value={detentionReview} onChange={setDetentionReview} />

            <FormCheckBox label="Gladue Submissions" id="gladue-submissions"
                value={gladueSubmissions} onChange={setGladueSubmissions} />

            <FormCheckBox label="Chapter 8" id="chapter-8"
                value={charter8} onChange={setCharter8} />

            <FormCheckBox label="Mental health" id="mental-health"
                value={mentalHealth} onChange={setMentalHealth} />
        </div>
    )

    // useEffect(() => {
    //     setIsBlockFee((pleaType === "guilty" || pleaType === "guilty-withdrawn") && EXCLUSION_LIST.indexOf(offenceType) < 0)
    // }, [pleaType, offenceType])
    const isBlockFee = ((pleaType === "guilty" || pleaType === "guilty-withdrawn") && EXCLUSION_LIST.indexOf(offenceType) < 0)

    const blockFeeElement = (
        <div className="calculator-block-fee-root">
            {ancillaryElement}
            <div className="calculator-block-rate-category">
                <span>Block Rate Category: </span>
                <span>{blockRateCategory}</span>
            </div>
            <div className="calculator-block-rate-fee">
                <span>Block Fee: $</span>
                <span>{blockFee}</span>
            </div>
        </div>
    )
    const hourlyElement = (
        <div>
            <FormDropDown label="Severity of Charge" options={severityOfChargeOptions}
                value={severityOfCharge} onChange={setSeverityOfCharge} />
            <div className="calculator-hourly">
                <div className="calculator-category-input">
                    <div>
                        <span>Invoice Id: </span>
                        <span className="calculator-category-invoice-id">{invoiceUid == null ? "New Invoice" : invoiceUid}</span>
                    </div>
                    <FormText label="Title" onChange={setTitle} value={title} />
                    <FormTextArea label="Description" onChange={setDescription} value={description} rows={5} />

                    <div className="calculator-category-offence-type">{offenceType}</div>
                    <div className="calculator-category-section"><span>Section: </span><span>{offenceSection}</span></div>
                    <div className="calculator-category-input-group">
                        <FormDropDown label="Lawyer Tier" className="calculator-lawyer-tier" options={LAWYER_TIER} value={lawyerTier} onChange={setLawyerTier} />
                    </div>
                </div>
                <div className="calculator-days-input">
                    <FormNumber label="Total number of days in court after the first day" value={daysInCourt} onChange={setDaysInCourt} />
                </div>
                <div className="calculator-hours-input">
                    <FormNumber label="Total number of hours in attendance at court" value={hoursInCourt} onChange={setHoursInCourt} />
                </div>
                {ancillaryElement}
            </div>
            <div className="calculator-submit-wrap">
                <TextButton label="Save" theme={Theme.Primary} onClick={onSubmit} />
            </div>
        </div>
    )

    useEffect(() => {
        if (offenceType && isBlockFee) {
            api.post("/legal-aid/block-fee", {
                offence_type: offenceType,
                is_northern_rate: isNorthernAreaCourt,
                are_charges_withdrawn: pleaType === "guilty-withdrawn",

                judicial_pre_trial_ocj1: judicialPretrialOcj1,
                judicial_pre_trial_ocj2: judicialPretrialOcj2,
                judicial_pre_trial_scj: judicialPretrialScj,
                judicial_interim_release_order: judicialInterimReleaseOrder,
                judicial_interim_release_order_524: judicialInterimReleaseOrder524,
                bail_hearing_524: bailHearing524,
                bail_variation: bailVariation,
                bail_review_scj: bailReviewScj,
                detention_review: detentionReview,
                gladue_submissions: gladueSubmissions,
                charter8: charter8,
                mental_health: mentalHealth,
            })
                .then(rsp => { setBlockFee(rsp.data.block_rate); setBlockRateCategory(rsp.data.block_rate_category) })
        }
    }, [
        isBlockFee, offenceType, isNorthernAreaCourt, pleaType, judicialPretrialOcj1, judicialPretrialOcj2,
        judicialPretrialScj, judicialInterimReleaseOrder, judicialInterimReleaseOrder524, bailHearing524,
        bailVariation, bailReviewScj, detentionReview, gladueSubmissions, charter8, mentalHealth
    ])

    const deleteModalPanel = (
        <ModalPanel options={deleteModalMap} >
            <div>Are you sure you want to delete this invoice?</div>
        </ModalPanel>
    )

    return (
        <div className="calculator-root theme-surface">
            <div className="calculator-invoice-options">
                <TextButton label="New Invoice" theme={Theme.Primary} className="format-font-medium" onClick={resetState} />
                <ImageButton alt="delete invoice" className="calculator-delete-invoice" src='/icons/delete.svg'
                    onClick={() => setShowDeleteModal(true)} />
                {showDeleteModal ? deleteModalPanel : null}
            </div>
            <div className="calculator-title format-font-header-small">Calculator</div>
            <div className="calculator-northern-rate-wrap">
                <FormCheckBox id="is-northern-rate format-font-small" label='Apply Northern Rates'
                    value={isNorthernAreaCourt} onChange={setIsNorthernAreaCourt}
                />
            </div>

            <OffencesLookup
                value={searchOffenceType}
                pleaType={pleaType} onChangePleaType={setPleaType} onChange={setSearchOffenceType}
                onNewOffence={(offence, section, tables) => {

                    // set internal value and actual value
                    setSearchOffenceType(offence)
                    setOffenceType(offence)
                    setOffenceSection(section)

                    let newSeverityOptions = new Map(Array.from(SEVERITY_OF_CHARGE.entries()).filter(s => tables.indexOf(s[0]) >= 0))
                    let newSeverityOfCharge = newSeverityOptions.keys().next().value

                    // cascade to other values
                    setSeverityOfCharge(newSeverityOfCharge)
                    setSeverityOfChargeOptions(newSeverityOptions)
                }}
                onReset={() => { setSeverityOfChargeOptions(SEVERITY_OF_CHARGE); setOffenceSection("") }} />

            <FormDropDown label="Plea Type" options={PLEA_TYPE} value={pleaType} onChange={setPleaType} />
            {offenceType && isBlockFee ? blockFeeElement : null}
            {offenceType && !isBlockFee ? hourlyElement : null}

        </div >
    );
}

export default Calculator;
