export function TermsOfService() {

    return (
        <div>
            <h1>Terms and Conditions</h1>
            <p>
                These terms and conditions (the "Terms and Conditions") govern the use of Billablez.ca (the "Site").
                This Site is owned and operated by John Fitzmaurice & Jarrod Stone. This Site is an ecommerce website.
            </p>
            <p>
                By using this Site, you indicate that you have read and understand these Terms and Conditions and
                agree to abide by them at all times.
            </p>
            <h2>Intellectual Property:</h2>
            <p>
                All content published and made available on our Site is the property of Billablez.ca and the Site's
                creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.
            </p>
            <h2>Accounts:</h2>
            <p>When you create an account on our Site, you agree to the following:</p>
            <ol>
                <li>You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and</li>
                <li>All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.  We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.</li>
            </ol>
            <h2>Third Party Goods and Services:</h2>
            <p>
                Our Site may offer goods and services from third parties. We cannot guarantee the quality or
                accuracy of goods and services made available by third parties on our Site.
            </p>
            <h2>Links to Other Websites:</h2>
            <p>
                Our Site contains links to third party websites or services that we do not own or control. We are
                not responsible for the content, policies, or practices of any third party website or service linked
                to on our Site. It is your responsibility to read the terms and conditions and privacy policies of
                these third party websites before using these sites.
            </p>
            <h2>Limitation of Liability:</h2>
            <p>
                Billablez.ca and our directors, officers, agents, employees, subsidiaries, and affiliates will not
                be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees
                from your use of the Site.
            </p>
            <h2>Indemnity:</h2>
            <p>
                Except where prohibited by law, by using this Site you indemnify and hold harmless
                Billablez.ca and our directors, officers, agents, employees, subsidiaries, and affiliates from any
                actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your
                use of our Site or your violation of these Terms and Conditions.
            </p>
            <h2>Applicable Law:</h2>
            <p>
                These Terms and Conditions are governed by the laws of the Province of Ontario.
            </p>
            <h2>Severability:</h2>
            <p>
                If at any time any of the provisions set forth in these Terms and Conditions are found to be
                inconsistent or invalid under applicable laws, those provisions will be deemed void and will be
                removed from these Terms and Conditions. All other provisions will not be affected by the
                removal and the rest of these Terms and Conditions will still be considered valid.
            </p>

            <h2>Disclaimer:</h2>
            <p>
                Billablez.ca is intended as a tool to assist in the estimation of Ontario Legal Aid billable hours and block
                rates in conjunction with the Legal Aid Ontario Handbook and other sources of information provided by
                Legal Aid Ontario. Billablez.ca is not intended as a substitute for the official sources of information
                provided by Legal Aid Ontario. All Block Rate and Legal Aid Billable Hours calculations should be checked
                using the Legal Aid Ontario handbook and all other relevant sources of information provided by Legal Aid
                Ontario before being submitted to Legal Aid. Billablez.ca cannot be held liable for any error,
                omission, miscalculation, overestimate, underestimate of Legal Aid Billable Hours or Block
                rates that result from the use of this site.
            </p>
            <h2>Changes:</h2>
            <p>
                These Terms and Conditions may be amended from time to time in order to maintain
                compliance with the law and to reflect any changes to the way we operate our Site and the way
                we expect users to behave on our Site. We will notify users by email of changes to these Terms
                and Conditions or post a notice on our Site.
            </p>
            <h2>Contact Information:</h2>
            <p>
                If you have any questions, concerns or complaints, you can contact our privacy officer, John
                Fitzmaurice, at: <b>billablezcanada@gmail.com</b>
            </p>
        </div>
    )
}