export function PrivacyPolicy() {

    return (
        <div>
            <h1>Privacy Policy:</h1>
            <h2>Purpose:</h2>
            <p>
                The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the
                following:
                <ol>
                    <li>The personal data we will collect;</li>
                    <li>Use of collected data;</li>
                    <li>Who has access to the data collected;</li>
                    <li>The rights of Site users; and</li>
                    <li>The Site's cookie policy.</li>
                </ol>
                This Privacy Policy applies in addition to the terms and conditions of our Site.
            </p>
            <h2>Consent:</h2>
            <p>
                By using our Site users agree that they consent to:
                <ol>
                    <li>The conditions set out in this Privacy Policy; and</li>
                    <li>The collection, use, and retention of the data listed in this Privacy Policy.</li>
                </ol>
                Personal Data We Collect
                We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect
                any additional data beyond the data listed below without notifying you first.
                Data Collected Automatically
                When you visit and use our Site, we may automatically collect and store the following information:
                <ol>
                    <li>IP address;</li>
                    <li>Location;</li>
                    <li>Clicked links; and</li>
                    <li>Content viewed.</li>
                </ol>
                Data Collected in a Non-Automatic Way
                We may also collect the following data when you perform certain functions on our Site:
                <ol>
                    <li>Email address;</li>
                    <li>Phone number;</li>
                    <li>Payment information; and</li>
                    <li>Auto fill data.</li>
                </ol>
                This data may be collected using the following methods:
                <ol><li>Creating an account.</li></ol>
            </p>

            <h2>How We Use Personal Data:</h2>
            <p>
                Data collected on our Site will only be used for the purposes specified in this Privacy Policy or
                indicated on the relevant pages of our Site. We will not use your data beyond what we disclose in this
                Privacy Policy.
                The data we collect automatically is used for the following purposes:
                <ol><li>Statistics and ensuring the validity of information presented.</li></ol>
                The data we collect when the user performs certain functions may be used for the following purposes:
                <li>Bill for services; and</li>
                <li>Track previous cases submitted.</li>
                Who We Share Personal Data With:
                <ol><li>Employees</li></ol>
                We may disclose user data to any member of our organization who reasonably needs access to user data
                to achieve the purposes set out in this Privacy Policy.
            </p>
            <h2>Other Disclosures:</h2>
            <p>
                We will not sell or share your data with other third parties, except in the following cases:
                <ol>
                    <li>If the law requires it;</li>
                    <li>If it is required for any legal proceeding;</li>
                    <li>To prove or protect our legal rights; and</li>
                    <li>To buyers or potential buyers of this company in the event that we seek to sell the company.</li>
                </ol>
                If you follow hyperlinks from our Site to another site, please note that we are not responsible for
                and have no control over their privacy policies and practices.
            </p>
            <h2>How Long We Store Personal Data:</h2>
            <p>
                User data will be stored until the purpose the data was collected for has been achieved.
                You will be notified if your data is kept for longer than this period.
            </p>
            <h2>How We Protect Your Personal Data:</h2>
            <p>
                Encryption & user access control lists
                While we take all reasonable precautions to ensure that user data is secure and that users are protected,
                there always remains the risk of harm. The Internet as a whole can be insecure at times and therefore
                we are unable to guarantee the security of user data beyond what is reasonably practical.
            </p>
            <h2>Children:</h2>
            <p>
                The minimum age to use our website is 18 years of age. We do not knowingly collect or use personal
                data from children under 13 years of age. If we learn that we have collected personal data from a child
                under 13 years of age, the personal data will be deleted as soon as possible. If a child under 13 years of
                age has provided us with personal data their parent or guardian may contact our privacy officer.
                How to Access, Modify, Delete, or Challenge the Data Collected
                If you would like to know if we have collected your personal data, how we have used your personal
                data, if we have disclosed your personal data and to who we disclosed your personal data, or if you
                would like your data to be deleted or modified in any way, please contact our privacy officer here:
            </p>
            <h2>Cookie Policy:</h2>
            <p>
                A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating
                to the user's browsing habits. You can choose to be notified each time a cookie is transmitted. You can
                also choose to disable cookies entirely in your internet browser, but this may decrease the quality of
                your user experience.
                We use the following types of cookies on our Site:
                <ol>
                    <li>Functional cookies</li>
                    Functional cookies are used to remember the selections you make on our Site so that your
                    selections are saved for your next visits; and
                    <li>Third-Party Cookies</li>
                    Third-party cookies are created by a website other than ours. We may use third-party cookies to
                    achieve the following purposes:
                    <ol type="a"><li>User Authentication.</li></ol>
                </ol>
            </p>
            <h2>Modifications:</h2>
            <p>
                This Privacy Policy may be amended from time to time in order to maintain compliance with the law
                and to reflect any changes to our data collection process. When we amend this Privacy Policy we will
                update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically
                review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify
                users by email of changes to this Privacy Policy.
            </p>
        </div>
    )
}