import axios, { InternalAxiosRequestConfig } from 'axios';

const api = axios.create({ baseURL: '/api/v1', timeout: 60000, })

export const redirectIfNotAuth = () => {
    if (window.location.pathname.startsWith("/auth")) {
        return
    }

    const csrf = localStorage.getItem("x-csrf-token")
    if (!csrf) {
        window.location.pathname = "/auth/login"
    }
}

api.interceptors.request.use(onRequest, err => { return Promise.reject(err) })
api.interceptors.response.use(rsp => { return Promise.resolve(rsp) }, onResponseError)

function onRequest(cfg: InternalAxiosRequestConfig<any>) {

    // Add CSRF token to all requests
    if (cfg.headers) {
        let csrf = localStorage.getItem("x-csrf-token")
        if (csrf && csrf !== "") {
            cfg.headers["x-csrf-token"] = csrf
        }
    }
    return Promise.resolve(cfg)
}

function onResponseError(err: any) {
    if (err.config.url === "auth/refresh") {
        return Promise.reject(err)
    } else if (err.response.status === 401) {
        // Clear the CSRF token
        localStorage.removeItem("x-csrf-token")
        window.location.href = "/auth/login"
        return Promise.reject(err)
        // TODO attempt silent refresh

        // If reauthentication was successful, retry the request
        // return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
        //   refreshAuth().then(rsp => {
        //     api.request(err.config).then(resolve).catch(reject)
        //   }).catch(e => {
        //     if (window.location.pathname !== "/auth/login") {
        //       window.location.href = "/auth/login"
        //     }
        //     reject(err)
        //   })
        // })
    } else {
        return Promise.reject(err)
    }
}

export default api