import "./form.css"
import { useState } from "react";
import { InputNumberBox, InputPasswordBox, InputTextBox } from "./input";

type TextProps = {
    value: string
    label: string
    id?: string
    inputAttrs?: React.HtmlHTMLAttributes<HTMLInputElement>
    className?: string
    onChange: (s: string) => void
}

export function FormText(props: TextProps) {
    const clazz = props.className ? "form-text-root " + props.className : "form-text-root"
    return (<div id={props.id} className={clazz}>
        <div className="form-text-label">{props.label}</div>
        <InputTextBox value={props.value} inputAttrs={props.inputAttrs} placeholder={props.label} onChange={props.onChange} />
    </div>)
}

type NumberProps = {
    value: number
    label: string
    id?: string
    inputAttrs?: React.HtmlHTMLAttributes<HTMLInputElement>
    className?: string
    onChange: (s: number) => void
}

export function FormNumber(props: NumberProps) {
    const clazz = props.className ? "form-number-root " + props.className : "form-number-root"
    return (<div id={props.id} className={clazz}>
        <div className="form-number-label">{props.label}</div>
        <InputNumberBox value={props.value} inputAttrs={props.inputAttrs} onChange={props.onChange} />
    </div>)
}

type PasswordProps = {
    value: string
    id?: string
    className?: string
    label: string
    onChange: (s: string) => void
}

export function FormPassword(props: PasswordProps) {
    const clazz = props.className ? "form-password-root " + props.className : "form-password-root"
    return (<div id={props.id} className={clazz}>
        <div className="form-password-label">{props.label}</div>
        <InputPasswordBox value={props.value} onChange={props.onChange} />
    </div>)
}

type TextAreaProps = {
    value: string
    label: string
    rows?: number
    id?: string
    className?: string
    defaultValue?: string
    onChange: (s: string) => void
}

export function FormTextArea(props: TextAreaProps) {
    const [isFocused, setFocus] = useState(false)
    const [value, setValue] = useState(props.value)
    const save = function (newValue: string) {
        setValue(newValue)
        props.onChange(newValue)
    }

    const clazz = props.className ? "form-textarea-root " + props.className : "form-textarea-root"
    return (<div id={props.id} className={clazz}>
        <div className="form-textarea-label">{props.label}</div>
        <textarea className="form-textarea-input"
            placeholder={props.label}
            value={isFocused ? value : props.value}
            defaultValue={props.defaultValue} rows={props.rows} style={{ resize: "none" }}
            onChange={e => { setValue(e.target.value) }}
            onFocus={e => { setFocus(true); setValue(props.value) }}
            onBlur={e => { setFocus(false); save(value) }}
        />
    </div>
    )
}

type DropDownProps = {
    value: string
    label: string
    id?: string
    className?: string
    options: Map<string | number, string>
    onChange: (s: string) => void
}
export function FormDropDown(props: DropDownProps) {
    const optArr = Array.from(props.options.entries())

    const clazz = props.className ? "form-dropdown-root " + props.className : "form-dropdown-root"
    return (<div id={props.id} className={clazz}>
        <div className="form-dropdown-label">{props.label}</div>
        <select className="form-dropdown-select" value={props.value} onChange={e => props.onChange(e.target.value)}>
            {optArr.map(([k, v]) => <option key={k} value={k}>{v}</option>)}
        </select>
    </div>)
}

type CheckBoxProps = {
    value: boolean
    label: string
    id: string
    className?: string
    onChange: (b: boolean) => void
}

export function FormCheckBox(props: CheckBoxProps) {

    return (<div id={props.id} className={props.className ? "form-checkbox-root " + props.className : "form-checkbox-root"}>
        <input type="checkbox" name={props.id} onChange={evt => props.onChange(evt.target.checked)} checked={props.value}/>
        <label htmlFor={props.id}>{props.label}</label>
    </div>)
}

