import './App.css';
import './format.css'
import './theme.css'
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react';
import api, { redirectIfNotAuth } from './api';
import { TextButton } from './component/input';
import { Theme } from './theme';
import { InvoiceList } from './legal_aid/Invoice';

export function App() {

    useEffect(() => { redirectIfNotAuth() }, [])

    return (
        <div className="app-root theme-background">
            <Outlet />
        </div>
    );
}

export function Workspace() {

    const logout = () => {
        api.post("/auth/logout").then(rsp => {
            window.localStorage.removeItem("x-csrf-token")
            window.location.pathname = "/auth/login"
        })
    }

    return (
        <div className="workspace-root">
                <div className="workspace-header">
                    <div className="workspace-title">
                        <div className="format-font-header-medium">Billablez</div>
                        <div className="workspace-tos-pp-wrap">
                            <a href="/tos" target="_blank"><span className="format-font-subscript">Terms of Service</span></a>
                            <a href="/pp" target="_blank"><span className="format-font-subscript">Privacy Policy</span></a>
                        </div>
                    </div>
                    <TextButton label="Logout" theme={Theme.Primary} onClick={logout} />
                </div>
                <div className="workspace">
                    <div className="workspace-sidebar-left theme-surface">
                        <InvoiceList />
                    </div>
                    <div className="workspace-centre">
                        <Outlet />
                    </div>
                    <div className="workspace-sidebar-right theme-surface">
                        <a href="https://www.legalaid.on.ca/lawyers-legal-professionals/accounts-billing/block-fees/" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Block Fees</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/lawyers-legal-professionals/accounts-billing/block-fees/block-fees-list-of-eligible-charges/" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Block Fees Eligible Charges</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/lawyers-legal-professionals/accounts-billing/block-fees/block-fee-rates-and-pricing/" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Block Fees Rates and Pricing</div>
                        </a>
                        <a href="https://legalaid.on.ca/lasa2020/lasa-2020-rules-and-policies/" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Legal Aid Rules and Polcies 2020</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/wp-content/uploads/Legal-Aid-Services-Act-2020_Rules-EN.pdf" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Legal Aid Services Act 2020</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/wp-content/uploads/LAO-tariff-and-billing-handbook-EN.pdf" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Tariff and Billing Handbook</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/wp-content/uploads/LAO-disbursements-handbook-EN.pdf" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Disbursements Handbook</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/lawyers-legal-professionals/accounts-billing/tariff-billing/" target="_blank" rel="noreferrer">
                            <div className="format-font-small">Tariff Billing</div>
                        </a>
                        <a href="https://www.legalaid.on.ca/lawyers-legal-professionals/accounts-billing/how-to-bill/" target="_blank" rel="noreferrer">
                            <div className="format-font-small">How to Bill</div>
                        </a>

                    </div>
                </div>
        </div>
    );
}
